const videoPlay = () => {
	const videoSmartphone = document.querySelector('.video-smartphone');
	const video = videoSmartphone.querySelector('video');
	const playButton = videoSmartphone.querySelector('.video-smartphone__play');

	playButton.addEventListener('click', () => {
		playButton.classList.add('video-smartphone__play_hidden');
		video.play();
	})

	video.addEventListener('ended', () => {
		playButton.classList.remove('video-smartphone__play_hidden');
	})
}
