const backgroundAnimation = () => {
	const appLayout = document.querySelector('.app');
	const animationTargets = document.querySelectorAll('.presentation-block_grey');

	function handler(entries, observer) {
		for (entry of entries) {
			if (entry.isIntersecting) {
				appLayout.classList.add('app_grey')
			} else {
				appLayout.classList.remove('app_grey')
			}
		}
	}

	let observer = new IntersectionObserver(handler);

	animationTargets.forEach(target => {
		observer.observe(target);
	});
}
