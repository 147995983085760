const animationObserver = () => {
	const triggers = document.querySelectorAll('[animationTrigger]');

	const cb = (entries) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				entry.target.classList.add('active');
			}
		})
	}

	const sectionObserver = new IntersectionObserver(cb, {
		threshold: 0.5
	});

	triggers.forEach(trigger => sectionObserver.observe(trigger));
}
