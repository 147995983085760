const review = () => {
	const reviews = document.querySelectorAll('.review');

	reviews.forEach(item => {
		const moreButton = item.querySelector('.review__more');

		moreButton.addEventListener('click', () => {
			item.classList.toggle('review_active');
		});
	});
}
