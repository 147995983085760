const setPosition = () => {
	const services = document.querySelectorAll('.service');
	const respondForm = document.querySelector('.respond-form');
	const positionInput = respondForm.querySelector('input[name="position"]');
	const positionPlacement = respondForm.querySelector('.respond-form__position');

	  for (let i = 0; i < services.length; i++) {
		  const respondButtons = services[i].querySelectorAll('.service__res-btn');
		  const vacancyPosition = services[i].querySelector('.service-welcome__inner .medium-heading');

		  respondButtons.forEach(item => {
			  item.addEventListener('click', () => {
				  positionInput.value = vacancyPosition.textContent;
				  positionPlacement.innerHTML = vacancyPosition.textContent;
			  });
		  })
	  }
}
