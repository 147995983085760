const progressSlider = () => {
	const sliders = document.querySelectorAll('.progress-slider');

	sliders.forEach(item => {
		const carousel = item.querySelector('.progress-slider__carousel');
		const progressLine = item.querySelector(".progress-slider__line div");

		setProgress(carousel, progressLine);

		carousel.onscroll = () => {setProgress(carousel, progressLine)};
		carousel.addEventListener("mousewheel", scrollHorizontal);
	})
}

const setProgress = (carousel, progressLine) => {
	const direction = document.querySelector('html').getAttribute('dir');

	let winScroll = carousel.scrollLeft;
	let width = carousel.scrollWidth - carousel.clientWidth;
	let scrolled = (winScroll / width) * 90;

	if (direction === "rtl") {
		progressLine.style.width = (10 - scrolled) + "%";
	} else  {
		progressLine.style.width = (10 + scrolled) + "%";
	}


}



