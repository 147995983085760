const parallax = () => {
	document.addEventListener("mousemove", translate);

	function translate(event) {
		this.querySelectorAll("[data-parallax-value]").forEach((shift) => {
			const position = shift.getAttribute("data-parallax-value");
			const x = (window.innerWidth - event.pageX * position) / 90;
			const y = (window.innerHeight - event.pageY * position) / 90;

			shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
		});
	}
}
