const setFile = () => {
	const input = document.querySelector('.file-input input');
	const textTarget = document.querySelector('.file-input label p');
	const fileClose = document.querySelector('.file-input__close');

	textTarget.innerHTML = input.value.split('/').pop().split('\\').pop();
	fileClose.classList.add('file-input__close_active');

	fileClose.addEventListener('click', () => {
		input.value = '';
		textTarget.innerHTML = 'Attach your resume here';
		fileClose.classList.remove('file-input__close_active');
	});
}
