//TODO: NEED ELEGANT REFACTOR (Valentin)

const switcher = (switcher) => {

	const direction = document.querySelector('html').getAttribute('dir');


	const navbar = switcher.querySelector('.nav');
	const navItems = switcher.querySelectorAll(".nav__item");
	const tabsItems = switcher.querySelectorAll('.tabs__item');

	navItems[0].classList.add('nav__item_active');

	if (!tabsItems.length == 0) {
		tabsItems[0].classList.add('tabs__item_active')
	}

	const activeLink = switcher.querySelector('.nav__item.nav__item_active');

	const slide = switcher.querySelector('.nav__slide');

	if (direction === "rtl") {
		slide.style.transform = `translateX(-${!activeLink.offsetLeft + 'px'})`
	} else {
		slide.style.transform = `translateX(${activeLink.offsetLeft + 'px'})`
	}

	slide.style.width = `${activeLink.offsetWidth + 'px'}`;

		for (let i = 0; i < navItems.length; i++) {
			navItems[i].addEventListener('click', () => {
				for (let k = 0; k < navItems.length; k++) {
					navItems[k].classList.remove('nav__item_active');

					if (!tabsItems.length == 0) {
						tabsItems[k].classList.remove('tabs__item_active');
					}
				}

				navItems[i].classList.add('nav__item_active');

				if (!tabsItems.length == 0) {
					tabsItems[i].classList.add('tabs__item_active');
				}

				if (direction === "rtl") {
					slide.style.transform = `translateX(-${navbar.offsetWidth - navItems[i].offsetLeft - navItems[i].offsetWidth + 'px'})`
				} else {
					slide.style.transform = `translateX(${navItems[i].offsetLeft + 'px'})`
				}
				slide.style.width = `${navItems[i].offsetWidth + 'px'}`;
			})
		}
}
