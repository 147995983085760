AOS.init({
	once: true
});

window.addEventListener('load', () => {
	AOS.refresh();
});

//Functions
headerBurger();
hideHeader();
offCanvas();

if (window.innerWidth > 1025) {
	parallax();
}
// setService();
formValidation(document.querySelector('.contact-form'));
customSelect();

switcher(document.querySelector('.footer .switcher'));

