const accordion = () => {
	const accordions = document.querySelectorAll('.accordion');

	for (let i = 0; i < accordions.length; i++) {
		const accordionHeader = accordions[i].querySelector('.accordion__header');

		accordionHeader.addEventListener('click', (e) => {
			e.stopPropagation();

			if (accordions[i].classList.contains('accordion_active')) {
				accordions[i].classList.remove('accordion_active');
			} else {
				for (let k = 0; k < accordions.length; k++) {
					accordions[k].classList.remove('accordion_active');
				}

				accordions[i].classList.add('accordion_active');
			}
		});
	}

	document.addEventListener('click', () => {
		for (let k = 0; k < accordions.length; k++) {
			accordions[k].classList.remove('accordion_active');
		}
	});
}
