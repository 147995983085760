const hideHeader = () => {
	const header = document.querySelector('.header');
	const target = document.querySelector('.get-in-touch');

	const options = {
		threshold: .4
	}

	const handler = (entries, observer) => {
		for (entry of entries) {
			if (entry.isIntersecting) {
				header.classList.add('header_hidden')
			} else {
				header.classList.remove('header_hidden')
			}
		}
	}

	let observer = new IntersectionObserver(handler, options);

	observer.observe(target);
}
