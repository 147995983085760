const copyLink = () => {
  const copyTrigger = document.querySelector('#copyTrigger');
  const tempInput = document.querySelector('.share__temp');
  const sharePrompt = document.querySelector('.share__prompt');



  copyTrigger.addEventListener('click', () => {
	  tempInput.classList.remove('share__temp_hidden');

	  tempInput.value = window.location.href;
	  tempInput.select();

	  document.execCommand('copy');

	  tempInput.classList.add('share__temp_hidden');

	  if(!sharePrompt.classList.contains('share__prompt_active')) {
		  sharePrompt.classList.add('share__prompt_active');
	  }
  });
}
