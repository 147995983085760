class Vacancies {
	constructor(container) {
		this.container = container;
		this.searchField = "";
		this.filters = [];
	}

	static disableFiltersBox() {
		document.querySelector('.filter-form').classList.add("filter-form_disabled");
	}

	static activateFiltersBox() {
		document.querySelector('.filter-form').classList.remove("filter-form_disabled");
	}

	static disableSearchClose() {
		document.querySelector('.search-field .search-field__close').classList.remove('search-field__close_active');
	}

	static activateSearchClose() {
		document.querySelector('.search-field .search-field__close').classList.add('search-field__close_active');
	}

	static resetSearchText() {
		document.querySelector('.search-field__input input').value = '';
	}

	resetContainer() {
		this.container.querySelector('.vacancies__result').innerHTML = '';
	}

	renderContainer(data) {
		this.container.querySelector('.vacancies__result').insertAdjacentHTML('beforeend', data);
	}

	setFilters(filter) {
		if(this.filters.includes(filter)) {
			this.filters = this.filters.filter(i => i !== filter);
		} else {
			this.filters.push(filter)
		}
	}

	setSearchField(text) {
		this.searchField = text;
	}

	activateResultText() {
		this.container.querySelector('.vacancies__result-text').classList.add('vacancies__result-text_active');
	}

	disableResultText() {
		this.container.querySelector('.vacancies__result-text').classList.remove('vacancies__result-text_active');
	}

	resetSearchField() {
		this.sending("filters");
		this.disableResultText();
		Vacancies.activateFiltersBox();
		Vacancies.disableSearchClose();
		Vacancies.resetSearchText();
	}

	sending(type) {
		const data = new FormData();

		data.append('action', 'get_vacancy_items');

		if(type === "filters") {
			data.append('filter_label', this.filters);
		}

		if(type === "search") {
			data.append('search_input', this.searchField);
		}

		axios
			.post('/wp-admin/admin-ajax.php', data)
			.then((response) => {
				this.resetContainer();
				this.renderContainer(response.data);

				if(type === "search") {
					this.activateResultText();
					Vacancies.disableFiltersBox();
					Vacancies.activateSearchClose();
				}
			});
	}
}
