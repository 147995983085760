function scrollHorizontal(e) {
	const direction = document.querySelector('html').getAttribute('dir');

	let scrollPos = this.scrollLeft;

	e = window.event || e;

	let delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));

	if (direction === "rtl") {
		this.scrollLeft += (delta * 40)
	} else {
		this.scrollLeft -= (delta * 40)
	}

	let widthElem = this.scrollWidth;
	let widthBrowser = document.documentElement.clientWidth;


	if ((delta == 1 ) && (this.scrollLeft == 0)) return;

	if ((widthBrowser >= widthElem) || (scrollPos == this.scrollLeft)) return;

	e.preventDefault();
}
