const customSelect = () => {
	document.querySelectorAll('.custom-select').forEach(setupSelector);

	function setupSelector(selector) {
		let isActive = false;

		const removeDropDown = () => {
			selector.classList.remove('active');
			const dropDown = document.querySelector('.selector-options');
			if(dropDown) {
				dropDown.remove();
			}
			isActive = false;
		}

		selector.addEventListener('mousedown', e => {
			if(!isActive) {
				e.preventDefault();
				const select = selector.children[0];
				const dropDown = document.createElement('ul');
				isActive = true;
				dropDown.className = "selector-options";

				[...select.children].forEach(option => {
					const dropDownOption = document.createElement('li');
					dropDownOption.className = 'medium-text'
					dropDownOption.textContent = option.textContent;

					dropDownOption.addEventListener('mousedown', (e) => {
						e.stopPropagation();
						select.style.color = "var(--black-text)"
						select.value = option.value;
						selector.value = option.value;
						select.dispatchEvent(new Event('change'));
						selector.dispatchEvent(new Event('change'));
						removeDropDown();
					});
					selector.classList.add('active')
					dropDown.appendChild(dropDownOption);
				});

				selector.appendChild(dropDown);

				document.addEventListener('mousedown', (e) => {
					if(!selector.contains(e.target)) {
						removeDropDown();
					}
				});
			} else {
				e.preventDefault();
				removeDropDown();
			}
		});
	}
}



