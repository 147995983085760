class FormActions {
	constructor(form) {
		this.errors = [];
		this.form = form;
		this.validationFields = form.querySelectorAll('.validation-input');
		this.allFileds = form.querySelectorAll('.form-field__input');
	}

	validateEmail(email) {
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	validatePhone(phone) {
		let re = /^[0-9\s]*$/;
		return re.test(String(phone));
	}

	badResult(formField, prompt, text) {
		formField.classList.add('form-field_incorrect');
		prompt.innerText = text;
		this.errors.push(text);
	}

	goodResult(formField, prompt) {
		formField.classList.remove('form-field_incorrect');
		prompt.innerText = '';
	}

	validationActions() {
		this.errors = [];

		this.validationFields.forEach(formField => {
			const input = formField.querySelector('.form-field__input');
			const prompt = formField.querySelector('.form-field__prompt');

			if (input.value === '') {
				this.badResult(formField, prompt, 'Empty field');
			} else {
				this.goodResult(formField, prompt);
			}

			if (input.type === 'email' && input.value !== '') {
				if (!this.validateEmail(input.value)) {
					this.badResult(formField, prompt, 'Invalid value');
				} else {
					this.goodResult(formField, prompt);
				}
			}

			if (input.type === 'tel' && input.value !== '') {
				if (!this.validatePhone(input.value)) {
					this.badResult(formField, prompt, 'Invalid value');
				} else {
					this.goodResult(formField, prompt);
				}
			}
		});
	}

	sendingActions() {
		if (this.errors.length === 0) {
			const data = new FormData(this.form);

			data.append('action', this.form.getAttribute('data-axios-action'));

			const loadingTarget = document.querySelector(`${this.form.getAttribute('data-loading-target')}`);

			loadingTarget.classList.add(`${loadingTarget.classList[0] + "_loading"}`);

			axios
				.post('/wp-admin/admin-ajax.php', data)
				.then(() => {
					loadingTarget.classList.remove(`${loadingTarget.classList[0] + "_loading"}`);
					loadingTarget.classList.add(`${loadingTarget.classList[0] + "_done"}`);

					this.allFileds.forEach(field => {
						field.value = '';
					});

					if(this.form.contains(this.form.querySelector('select'))) {
						const select = this.form.querySelector('select');

						select.style.color = '';
						select.value = this.form.querySelectorAll('select option')[0].value;
					}

					if(this.form.contains(this.form.querySelector('input[type="file"]'))) {
						const fileInput = this.form.querySelector('input[type="file"]');

						fileInput.value = '';
						fileInput.closest(".file-input").querySelector('p.small-text').innerText = "Attach your resume here";
						fileInput.closest(".file-input").querySelector('.file-input__close').classList.remove('file-input__close_active');
					}

					setTimeout(() => {
						loadingTarget.classList.remove(`${loadingTarget.classList[0] + "_done"}`);
					}, 3000);
				});
		}
	}
}
