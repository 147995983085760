const headerBurger = () => {
	let burgerLine = document.querySelector('.burger'),
		offCanvas = document.querySelector('.off-canvas'),
		appLayout = document.querySelector('.app')

	burgerLine.addEventListener('click', ()=>{
		if (burgerLine.classList.contains('burger_active')) {
			burgerLine.classList.remove('burger_active');
		}
		else {
			burgerLine.classList.add('burger_active');
		}
		if (offCanvas.classList.contains('off-canvas_active')) {
			offCanvas.classList.remove('off-canvas_active');
		}
		else {
			offCanvas.classList.add('off-canvas_active');
		}
		if (appLayout.classList.contains('app_overlay')) {
			appLayout.classList.remove('app_overlay');
		}
		else {
			appLayout.classList.add('app_overlay');
		}
	});
}
