const setSelect = (value) => {
	const navItems = document.querySelectorAll('.options .nav .nav__item');

	for (let i = 0; i < navItems.length; i++) {
		const heading = navItems[i].querySelector('.medium-text').textContent;

		if (heading == value) {
			navItems[i].click();
		}
	}
}
