const offCanvas = () => {
  const offCanvas = document.querySelector('.off-canvas');
  const menuItems = offCanvas.querySelectorAll('.menu-item-has-children > a');

  if (menuItems) {
	  menuItems.forEach(item => {
		  item.addEventListener('click', (e) => {
			  e.preventDefault();

			  item.closest('.menu-item-has-children').classList.toggle('menu-item_active');
		  });
	  });
  }
}
