const customModal = (modal, trigger) => {
	const modalWrapper = modal.querySelector('.modal__wrapper');
	const closeButton = modal.querySelector('.modal__close');

		trigger.addEventListener('click', () => {
			modal.classList.add('modal_active');
		});

	closeButton.addEventListener('click', () => {
		modal.classList.remove('modal_active');
	});

	modal.addEventListener('click', (e) => {
		if(!modalWrapper.contains(e.target)) {
			modal.classList.remove('modal_active');
		}
	})
}
