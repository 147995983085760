const filters = () => {
	const triggerButton = document.querySelector('.vacancies__filter-btn');
	const header = document.querySelector('.header');
	const filters = document.querySelector('.asymmetrical__sticky');
	const filtersClose = document.querySelectorAll('.filters-close');

	const filterForm = document.querySelector('.filter-form');
	const labels = filterForm.querySelectorAll('label');
	const applyButton = filterForm.querySelector('.button');

	triggerButton.addEventListener('click', () => {
		triggerButton.classList.add('vacancies__filter-btn_hidden')
		filters.classList.add('asymmetrical__sticky_opened');
		header.classList.add('header_hidden');
	});

	filtersClose.forEach(item => {
		item.addEventListener('click', () => {
			triggerButton.classList.remove('vacancies__filter-btn_hidden')
			filters.classList.remove('asymmetrical__sticky_opened');
			header.classList.remove('header_hidden');
			applyButton.setAttribute('disabled', '')
		});
	});

	labels.forEach(label => {
		label.addEventListener('click', () => {
			applyButton.removeAttribute('disabled');
		});
	});

	applyButton.addEventListener('click', (e) => {
		e.preventDefault();
	});
}
