const personAnimation = () => {
	const mainImage = document.querySelector('.person__main-image');
	const referenceImages = document.querySelectorAll('.person__reference-image');
	const buildButtons = document.querySelectorAll('.build-button');

		for (let i = 0; i < buildButtons.length; i++) {
			buildButtons[i].addEventListener('mouseover', () => {
				mainImage.classList.remove('active');
				referenceImages[i].classList.add('active');
			})

			buildButtons[i].addEventListener('mouseleave', () => {
				mainImage.classList.add('active');
				referenceImages[i].classList.remove('active');
			})
		}
}


