const vacanciesFiltering = () => {
	const container = document.querySelector('.vacancies .asymmetrical__aside');
	const searchForm = document.querySelector('form.search-field');
	const filters = document.querySelectorAll('.filter-form input[type="checkbox"]');

	const vacanciesClass = new Vacancies(container, searchForm);

	filters.forEach(item => {
		item.addEventListener('change', () => {
			vacanciesClass.setFilters(item.getAttribute('data-id'));
			vacanciesClass.sending("filters");
		})
	});

	searchForm.addEventListener('submit', (e) => {
		e.preventDefault();

		vacanciesClass.setSearchField(e.target[0].value);
		vacanciesClass.sending("search");
	});

	searchForm.querySelector('.search-field__close').addEventListener('click', () => {
		vacanciesClass.resetSearchField();
	});
}
