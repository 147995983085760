const formValidation = (form) => {
	const formClass = new FormActions(form);

	  form.addEventListener('submit', (e) => {
		  e.preventDefault();

		  formClass.validationActions();
		  formClass.sendingActions();
	  });
}

