const navigationObserver = () => {
	const services = document.querySelectorAll('.service');
	const anchors = document.querySelectorAll('.switcher .nav a');
	const slide = document.querySelector('.switcher .nav__slide');
	const navbar = document.querySelector('.switcher .nav');
	const direction = document.querySelector('html').getAttribute('dir');


	const cb = (entries) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				anchors.forEach(anchor => {
					anchor.querySelector('.nav__item').classList.remove('nav__item_active');
				});

				const activeId = entry.target.id;
				const activeAnchor = document.querySelector(
					`.switcher .nav a[href="#${activeId}"]`
				);

				if (activeAnchor) {
					activeAnchor.querySelector('.nav__item').classList.add('nav__item_active');

					if (direction === "rtl") {
						slide.style.transform = `translateX(-${navbar.offsetWidth - activeAnchor.offsetLeft - activeAnchor.offsetWidth + 'px'})`

						setTimeout(() => {
							if (window.innerWidth < 769) {
								navbar.scrollLeft = navbar.offsetWidth - activeAnchor.offsetLeft - activeAnchor.offsetWidth;
							}
						}, 0);
					} else {
						slide.style.transform = `translateX(${activeAnchor.offsetLeft + 'px'})`

						setTimeout(() => {
							if (window.innerWidth < 769) {
								navbar.scrollLeft = activeAnchor.offsetLeft;
							}
						}, 0);
					}
					slide.style.width = `${activeAnchor.offsetWidth + 'px'}`;
				}
			}
		});
	}

	const sectionObserver = new IntersectionObserver(cb, {
		threshold: [0.2, 0.8]
	});

	services.forEach(service => sectionObserver.observe(service));
}
